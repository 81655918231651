<template>
  <div class="l-wrapper">
    <div class=" q-pa-md">
      <q-input outlined label="Cari Nama" v-model="searchTerm"></q-input>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-list>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredSales"
          :key="i"
          v-ripple
        >
          <q-item-section>
            <q-item-label> {{ val.username }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-btn-dropdown flat color="primary" dropdown-icon="more_horiz">
              <q-list>
                <q-item
                  clickable
                  v-close-popup
                  :to="`/customer/list/${val.id}`"
                >
                  <q-item-section>
                    <q-item-label
                      >Customer ({{ val.jml_customer }})</q-item-label
                    >
                  </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  :to="`/visit/history/${val.id}`"
                >
                  <q-item-section>
                    <q-item-label>Visit History</q-item-label>
                  </q-item-section>
                </q-item>
                <!-- <q-item clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Input Monthly Sales</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="noVisit({ id_user: val.id })"
              >
                <q-item-section>
                  <q-item-label>No Visit Today</q-item-label>
                </q-item-section>
              </q-item> -->
                <!-- <q-item clickable v-close-popup>
                <q-item-section>
                  <q-item-label>Edit Data</q-item-label>
                </q-item-section>
              </q-item> -->
              </q-list>
            </q-btn-dropdown>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn
      label="New Sales"
      color="primary"
      to="/sales/create"
      v-can="['ADMIN']"
    ></q-btn>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { onMounted, computed } from "vue";
import useSales from "./useSales";
import useVisit from "../Visit/useVisit";
import { useQuasar } from "quasar";
export default {
  setup() {
    const store = useStore();
    const { getSales, data } = useSales();
    const { noVisit } = useVisit();
    const $q = useQuasar();
    let searchTerm = ref("");

    let filteredSales = computed(() => {
      let sales = JSON.parse(JSON.stringify(data.sales));

      if (searchTerm.value != "") {
        sales = sales.filter((val) => {
          return (
            val.username
              .toLowerCase()
              .indexOf(searchTerm.value.toLowerCase()) != -1
          );
        });
      }

      return sales;
    });

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getSales();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      getSales,
      searchTerm,
      data,
      noVisit,
      store,
      filteredSales,
    };
  },
};
</script>
