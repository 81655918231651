import { reactive, inject, computed } from "vue";
import { useRouter } from "vue-router";
import Notify from 'quasar/src/plugins/Notify.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useStore } from "vuex";
export default function useUser() {
  const router = useRouter();
  const store = useStore();
  const $http = inject("$http");
  const $q = useQuasar();
  let curUser = computed(() => store.state.user);
  let data = reactive({
    sales: [],

    username: "",
  });

  let getSales = async () => {
    let resp = await $http.get(`/user/list-sales`);
    for (let item of resp.data) {
      item.label = item.username;
    }
    data.sales = resp.data;
  };

  let addSales = async (username) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      var md5 = require("md5");
      let user = {
        username: username,
        password: md5(username),
      };
      await $http.post(`/user/create-sales`, user);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
    router.go(-1);
  };

  let getOptSales = async () => {
    let resp = await $http.get(`/user/sales-option`);
    resp.data.unshift({ label: "Semua Sales", value: "semua" });
    data.sales = resp.data;
  };

  return {
    data,
    router,
    curUser,
    getSales,
    addSales,
    getOptSales,
    $q,
  };
}
